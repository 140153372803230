import { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Box,
  TextField,
  Button,
  Typography
} from '@mui/material'
import { apiUrl } from './config'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { AppContext } from './App'

const JoinNow = () => {
  const [email, setEmail] = useState('')
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const [showPassword, setShowPassword] = useState(false)
  const [passwordError, setPasswordError] = useState(false)
  const [isValidEmail, setIsValidEmail] = useState(false)
  const { setMessage } = useContext(AppContext)

  const handleEmailChange = (event) => {
    const value = event.target.value
    setEmail(value)
    setIsValidEmail(validateEmail(value))
  }

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return re.test(email)
  }
  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState)
  }
  const navigate = useNavigate()

  const handleChange = (event) => {
    setPasswordError(!/^.*(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\^$*.[\]{}()?\-"!@#%&/,><':;|_~`]).*$/.test(event.target.value))
    setPassword(event.target.value)
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    setIsLoading(true)
    setError(null)

    try {
      const response = await fetch(`${apiUrl}/join`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email, password, username })
      })
      const data = await response.json()
      if (response.ok) {
        navigate('/login')
        setMessage('User was created')
      } else {
        setError(data.error)
      }
    } catch (error) {
      setError(error.error)
    }

    setIsLoading(false)
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Typography variant="h4" gutterBottom>Join Now</Typography>
      {error && <Typography variant="subtitle1" sx={{ color: 'error.main' }}>{error}</Typography>}
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          mt: 2,
          '& > :not(style)': { m: 1, width: '25ch' }
        }}
      >
        <TextField
          label="Username"
          type="text"
          autoComplete="username"
          required
          value={username}
          onChange={(event) => setUsername(event.target.value)}
        />
        <TextField
          label="Email"
          type="email"
          autoComplete="email"
          required
          value={email}
          onChange={handleEmailChange}
          error={!isValidEmail}
          helperText={!isValidEmail ? 'Please enter a valid email address' : ''}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          id="password"
          autoComplete='new-password'
          value={password}
          onChange={handleChange}
          error={passwordError}
          helperText={passwordError ? 'Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character.' : ''}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={handleTogglePasswordVisibility}
                  aria-label="toggle password visibility"
                  edge="end"
                >
                  {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              </InputAdornment>
            )
          }}
        />
        <Button
          type="submit"
          variant="contained"
          disabled={isLoading || error || passwordError}
          sx={{ mt: 2 }}
        >
          Join Now
        </Button>
      </Box>
    </Box>
  )
}

export default JoinNow
