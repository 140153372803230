import { Box, Button, Typography } from '@mui/material'
import PropTypes from 'prop-types'

export default function App ({ handleAcceptCookies }) {
  return (
        <Box
            sx={{
              position: 'fixed',
              bottom: 0,
              left: 0,
              right: 0,
              zIndex: 9999,
              p: 2,
              bgcolor: 'background.default',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',

              backgroundColor: '#f8d7da'
            }}
        >
            <Typography variant="body1" sx={{ mr: 2 }}>
                We use cookies to collect data for analytics purposes. By continuing
                to use this site, you consent to our use of cookies.
            </Typography>
            <Button sx={{ whiteSpace: 'nowrap', minWidth: 'auto' }} onClick={handleAcceptCookies} variant="contained">
                I Agree
            </Button>
        </Box>
  )
}

App.propTypes = {
  handleAcceptCookies: PropTypes.func
}
