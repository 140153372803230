import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import {
  // TextField,
  Button,
  Box,
  // Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { apiUrl } from './config'
import { AppContext } from './App'

const AccountSettings = ({ setIsAuthenticated }) => {
  // const [newEmail, setNewEmail] = useState(email)
  // const [password, setPassword] = useState('')
  // const [confirmPassword, setConfirmPassword] = useState('')
  // const [passwordsMatch, setPasswordsMatch] = useState(true)
  const [openDialog, setOpenDialog] = useState(false)
  const { setMessage } = useContext(AppContext)

  const navigate = useNavigate()

  // const handleEmailChange = (event) => {
  //   setNewEmail(event.target.value)
  // }

  // const handlePasswordChange = (event) => {
  //   setPassword(event.target.value)
  //   setPasswordsMatch(event.target.value === confirmPassword)
  // }

  // const handleConfirmPasswordChange = (event) => {
  //   setConfirmPassword(event.target.value)
  //   setPasswordsMatch(event.target.value === password)
  // }

  // const handleEmailUpdate = async () => {
  //   try {
  //     const response = await fetch(`${apiUrl}/accounts/${accountId}/email`, {
  //       method: 'PATCH',
  //       headers: { 'Content-Type': 'application/json' },
  //       body: JSON.stringify({ email: newEmail })
  //     })

  //     if (response.ok) {
  //       console.log('Email updated successfully')
  //     } else {
  //       console.error('Failed to update email')
  //       if (response.status === 401) {
  //         localStorage.removeItem('token')
  //         setIsAuthenticated(false)
  //         return
  //       }
  //     }
  //   } catch (error) {
  //     console.error(error)
  //   }
  // }

  // const handlePasswordUpdate = async () => {
  //   if (password !== confirmPassword) {
  //     setPasswordsMatch(false)
  //     console.error('Passwords do not match')
  //     return
  //   }

  //   try {
  //     const response = await fetch(`${apiUrl}/accounts/${accountId}/password`, {
  //       method: 'PATCH',
  //       headers: { 'Content-Type': 'application/json' },
  //       body: JSON.stringify({ password })
  //     })

  //     if (response.ok) {
  //       console.log('Password updated successfully')
  //     } else {
  //       console.error('Failed to update password')
  //       if (response.status === 401) {
  //         localStorage.removeItem('token')
  //         setIsAuthenticated(false)
  //         return
  //       }
  //     }
  //   } catch (error) {
  //     console.error(error)
  //   }
  // }

  const handleDeleteAccount = async () => {
    setOpenDialog(true)
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
  }

  const handleConfirmDelete = async () => {
    const token = localStorage.getItem('token')
    try {
      const response = await fetch(`${apiUrl}/accounts`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`
        }
      })

      if (response.ok) {
        setMessage('Account deleted successfully')
        localStorage.removeItem('token')
        setIsAuthenticated(false)
        navigate('/')
      } else {
        setMessage('Failed to delete account')
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Box display="flex" flexDirection="column" alignItems="center" mt={2}>
      {/* <TextField label="Email" value={newEmail} onChange={handleEmailChange} sx={{ my: 2 }} />
      <Button variant="contained" color="primary" onClick={handleEmailUpdate} sx={{ my: 2 }}>
        Update Email
      </Button>
      <TextField label="Password" type="password" value={password} onChange={handlePasswordChange} sx={{ my: 2 }} />
      <TextField label="Confirm Password" type="password" value={confirmPassword} onChange={handleConfirmPasswordChange} sx={{ my: 2 }} />
      {!passwordsMatch && <Typography color="error">Passwords do not match</Typography>}
      <Button variant="contained" color="primary" onClick={handlePasswordUpdate} sx={{ my: 2 }} disabled={!passwordsMatch}>
        Update Password
      </Button> */}
      <Button
        variant="contained"
        color="secondary"
        onClick={handleDeleteAccount}
        sx={{ my: 2 }}
      >
        Delete Account
      </Button>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Delete Account'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete your account?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="secondary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

AccountSettings.propTypes = {
  setIsAuthenticated: PropTypes.func
}

export default AccountSettings
