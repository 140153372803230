import { Typography, Container, Grid } from '@mui/material'

const AppDescription = () => {
  return (
        <Container maxWidth="md" sx={{ mt: 5 }}>
            <Typography variant="h4" align="center" gutterBottom>
              Welcome to I Want To
            </Typography>
            <Typography variant="body1" align="center" paragraph>
                I Want To uses the power of OpenAI&apos;s GPT to help you find like-minded individuals with shared interests, buy and sell items with other users, and even find job opportunities.
            </Typography>
            <Grid container spacing={3} sx={{ mt: 5 }}>
                <Grid item xs={12} sm={4}>
                    <Typography variant="h5" align="center" gutterBottom>
                        Find People with Similar Interests
                    </Typography>
                    <Typography variant="body1" align="justify">
                        We know that it can be difficult to meet new people, especially those who share the same hobbies and passions as you. That&apos;s why we&apos;ve created this app to make it easier for you to connect with others who have similar interests and engage in meaningful conversations. I Want To utilizes the latest advances in artificial intelligence to match you with people who share the same interests as you.
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Typography variant="h5" align="center" gutterBottom>
                        Buy and Sell Items with Other Users
                    </Typography>
                    <Typography variant="body1" align="justify">
                        In addition to helping you find like-minded individuals, I Want To also offers a marketplace where you can buy and sell items with other users. Whether you&apos;re looking for a rare book, a vintage record, or even handmade crafts, our marketplace provides a platform for you to connect with other buyers and sellers and make transactions with ease.
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Typography variant="h5" align="center" gutterBottom>
                        Find Your Next Job Opportunity
                    </Typography>
                    <Typography variant="body1" align="justify">
                        Furthermore, I Want To includes a job board where users can post job listings or search for job opportunities posted by others. Whether you&apos;re looking for full-time work, part-time gigs, or even freelance opportunities, our job board provides a streamlined platform for you to connect with potential employers and find your next opportunity.
                    </Typography>
                </Grid>
            </Grid>
            <Typography variant="body1" align="center" sx={{ mt: 5 }}>
                We believe that I Want To is more than just a tool for making connections, but also a valuable resource for helping our community members achieve their goals and pursue their passions. So whether you&apos;re looking for a new friend, a unique item, or your dream job, I Want To has something for everyone. Join our community today and start exploring all that we have to offer!
            </Typography>
        </Container>
  )
}

export default AppDescription
