import React, { useState, useContext } from 'react'
import {
  Typography,
  TextField,
  Button,
  Grid,
  Paper,
  Alert
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { topPadding } from './styles'
import PropTypes from 'prop-types'
import { apiUrl } from './config'

import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { AppContext } from './App'

const LoginPage = (props) => {
  const { setIsAuthenticated } = props
  const { setMessage } = useContext(AppContext)
  const [credentials, setCredentials] = useState({
    username: '',
    password: ''
  })
  const [errorMessage, setErrorMessage] = useState('')
  const [showPassword, setShowPassword] = useState(false)

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState)
  }

  const navigate = useNavigate()
  const handleSubmit = async (event) => {
    event.preventDefault()

    try {
      const response = await fetch(`${apiUrl}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
      })
      const data = await response.json()
      if (response.ok) {
        setIsAuthenticated(true)
        localStorage.setItem('token', data?.token)

        navigate('/add-topic')
      } else {
        if (data.error === 'Please activate your account') {
          setMessage('Please activate your account')
        } else { setErrorMessage('Invalid username or password') }
      }
    } catch (error) {
      console.error(error)
      setErrorMessage('An error occurred while logging in')
    }
  }

  const handleChange = (event) => {
    const { name, value } = event.target

    setCredentials((prevCredentials) => ({
      ...prevCredentials,
      [name]: value
    }))
  }

  return (
    <div style={{
      display: 'flex',
      alignItems: 'center',
      height: `calc(100vh - ${topPadding}px )`
    }}>
      <Grid container justifyContent="center">
        <Paper sx={{ p: 4, maxWidth: 400 }}>
          <Typography variant="h5" component="h1" align="center" gutterBottom>
            Login
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoComplete="username"
              value={credentials.username}
              onChange={handleChange}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              id="password"
              autoComplete="current-password"
              value={credentials.password}
              onChange={handleChange}

              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleTogglePasswordVisibility}
                      aria-label="toggle password visibility"
                      edge="end"
                    >
                      {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 2 }}
            >
              Log In
            </Button>
            {errorMessage && (
              <Alert severity="error" sx={{ mt: 2 }}>
                {errorMessage}
              </Alert>
            )}
          </form>
        </Paper>
      </Grid>
    </div>
  )
}

export default LoginPage

LoginPage.propTypes = {
  setIsAuthenticated: PropTypes.func
}
