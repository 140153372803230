import React, { useState, useEffect, useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, Typography, Box, ListItem, ListItemText } from '@mui/material'
import { styled } from '@mui/system'
import AutoSizer from 'react-virtualized-auto-sizer'
import { FixedSizeList } from 'react-window'
import { apiUrl } from './config'
import PropTypes from 'prop-types'
import { AppContext } from './App'

const DeleteButton = styled(Button)({
  backgroundColor: '#f44336',
  color: '#fff',
  '&:hover': {
    backgroundColor: '#d32f2f'
  }
})

export default function Topic ({ setIsAuthenticated }) {
  const [topic, setTopic] = useState({})
  const navigate = useNavigate()
  const { setMessage } = useContext(AppContext)
  const { topicId } = useParams()

  const token = localStorage.getItem('token')
  useEffect(() => {
    const fetchTopic = async () => {
      try {
        const response = await fetch(`${apiUrl}/topics/${topicId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          }
        })
        if (response.status === 401) {
          localStorage.removeItem('token')
          setIsAuthenticated(false)
          return
        }
        const data = await response.json()
        setTopic(data.topic)
      } catch (error) {
        console.error(error)
      }
    }

    fetchTopic()
  }, [])

  const handleDeleteTopic = async () => {
    try {
      const response = await fetch(`${apiUrl}/topics/${topicId}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      if (response.status === 401) {
        localStorage.removeItem('token')
        setMessage('User Unauthorized')
        setIsAuthenticated(false)
        return
      }
      setMessage('Topic was succesfully deleted')
      navigate('/', { replace: true })
    } catch (error) {
      console.error(error)
    }
  }

  const renderMatch = ({ index, style }) => {
    const match = topic.matches[index]

    return (
      <ListItem key={index} style={style}>
        <ListItemText primary={`Topic: ${match.topic}`} secondary={`Username: ${match.username}`} />
      </ListItem>
    )
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: 1 }}>
      <DeleteButton variant="contained" onClick={handleDeleteTopic}>
        Delete Topic
      </DeleteButton>
      <Typography variant="h5" sx={{ mt: 2 }}>
        {topic?.topic}
      </Typography>
      {topic?.matches?.length > 0 && (
        <Box sx={{ width: '100%', height: 400, mt: 2 }}>
          <AutoSizer>
            {({ height, width }) => (
              <FixedSizeList height={height} width={width} itemSize={60} itemCount={topic.matches.length}>
                {renderMatch}
              </FixedSizeList>
            )}
          </AutoSizer>
        </Box>
      )}
    </Box>
  )
}

Topic.propTypes = {
  setIsAuthenticated: PropTypes.func
}
